 .footer{

    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    align-self: flex-end;
    background-color: #001529;
    bottom: 0px;
    justify-content: center;
    bottom:0px;
    color: rgb(240, 251, 240);
  }